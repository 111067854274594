import React, { useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// import CSS styles
import "./App.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import components
import Login from "./components/user_authentication/Login";
import Main from "./components/main_dashboard/Main";

// import context
import { WorkerProvider } from "./contexts/workerContext";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("isLoggedIn") == "true" ? true : false
    );
    return (
        <WorkerProvider>
            <Router>
                {isLoggedIn && (
                    <Switch>
                        <Route path="/mainDashboard" exact component={Main} />
                        <Route path="/" component={Main} />
                    </Switch>
                )}
                {!isLoggedIn && <Login />}
            </Router>
        </WorkerProvider>
    );
}

export default App;
